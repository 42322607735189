import React, { useState, useRef, useEffect } from 'react';
import { TextField, IconButton, InputAdornment, Box, CircularProgress } from '@mui/material';
import { LuSparkles } from "react-icons/lu";
import AudioIcon from '@mui/icons-material/KeyboardVoice';
import StopIcon from '@mui/icons-material/Stop';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../../redux/api';
import { useFormikContext } from 'formik';
import { processDosage } from './utils';

const AiInstructionInputField = ({ medication_input }) => {
    const { t } = useTranslation();
    const { convertDosage } = greyboxApiActions;
    const [postConvertDosage] = convertDosage.add();
    const [inputValue, setInputValue] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const recordingTimeoutRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const silenceStartRef = useRef(null);
    const silenceDetectionIntervalRef = useRef(null);
    const { setValues, values } = useFormikContext();

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSendClick = async () => {
        setIsLoading(true);
        const result = await postConvertDosage({ body: { medication_input, dosage_input: inputValue } });
        const newDosage = processDosage(result.data);
        setValues({
            ...values,
            ...newDosage,
            doseValue: newDosage.doseValue || values.doseValue,
            doseUnit: newDosage.doseUnit || values.doseUnit,
        });
        setIsLoading(false);
        setInputValue('');
    };

    const handleAudioClick = async () => {
        if (isRecording) {
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
                mediaRecorderRef.current.stop();
            }
        } else {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // Set up AudioContext and AnalyserNode for silence detection
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContextRef.current.createMediaStreamSource(stream);
            analyserRef.current = audioContextRef.current.createAnalyser();
            source.connect(analyserRef.current);
            analyserRef.current.fftSize = 2048;
            const bufferLength = analyserRef.current.fftSize;
            dataArrayRef.current = new Uint8Array(bufferLength);

            // Initialize MediaRecorder
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };
            mediaRecorderRef.current.onstop = async () => {
                setIsRecording(false);
                clearInterval(silenceDetectionIntervalRef.current);
                silenceDetectionIntervalRef.current = null;
                clearTimeout(recordingTimeoutRef.current);
                recordingTimeoutRef.current = null;

                if (audioContextRef.current) {
                    audioContextRef.current.close();
                    audioContextRef.current = null;
                }

                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                audioChunks.current = [];

                // Check if the recording is silent
                const isSilent = await checkIfSilent(audioBlob);
                if (isSilent) {
                    alert('No sound detected in the recording.');
                    return;
                }

                // Proceed with uploading the audio
                setIsLoading(true);
                const formData = new FormData();
                formData.append('medication_input', medication_input);
                formData.append('audio_file', audioBlob, 'recording.wav');

                const result = await postConvertDosage({ body: formData });
                const newDosage = processDosage(result.data);

                setValues({
                    ...values,
                    ...newDosage,
                });
                setIsLoading(false);
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);

            // Silence detection logic
            silenceStartRef.current = null;
            silenceDetectionIntervalRef.current = setInterval(() => {
                analyserRef.current.getByteTimeDomainData(dataArrayRef.current);
                let sum = 0;
                for (let i = 0; i < dataArrayRef.current.length; i++) {
                    const value = dataArrayRef.current[i] - 128;
                    sum += value * value;
                }
                const rms = Math.sqrt(sum / dataArrayRef.current.length);
                const volume = rms;

                const silenceThreshold = 5; // Adjust as needed
                const maxSilenceDuration = 5000; // 5 seconds

                if (volume < silenceThreshold) {
                    if (silenceStartRef.current === null) {
                        silenceStartRef.current = Date.now();
                    } else if (Date.now() - silenceStartRef.current > maxSilenceDuration) {
                        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
                            mediaRecorderRef.current.stop();
                            console.log('Recording stopped due to silence');
                        }
                    }
                } else {
                    silenceStartRef.current = null;
                }
            }, 200);

            // 30-second recording limit
            recordingTimeoutRef.current = setTimeout(() => {
                if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
                    mediaRecorderRef.current.stop();
                    console.log('Recording stopped after 30 seconds');
                }
            }, 30000);

            console.log('Recording started');
        }
    };

    // Function to check if the recording is silent
    const checkIfSilent = (audioBlob) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                const arrayBuffer = fileReader.result;
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
                    const rawData = audioBuffer.getChannelData(0);
                    let sum = 0;
                    for (let i = 0; i < rawData.length; i++) {
                        sum += rawData[i] * rawData[i];
                    }
                    const rms = Math.sqrt(sum / rawData.length);
                    const silenceThreshold = 0.01; // Adjust as needed
                    audioContext.close();
                    resolve(rms < silenceThreshold);
                });
            };
            fileReader.readAsArrayBuffer(audioBlob);
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendClick();
        }
    };

    // Cleanup on component unmount
    useEffect(() => {
        return () => {
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
                mediaRecorderRef.current.stop();
            }
            if (silenceDetectionIntervalRef.current) {
                clearInterval(silenceDetectionIntervalRef.current);
            }
            if (recordingTimeoutRef.current) {
                clearTimeout(recordingTimeoutRef.current);
            }
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
                fullWidth
                multiline
                placeholder={t('AI powered dosage instructions')}
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LuSparkles color="primary" size={24} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                color="primary"
                                onClick={
                                    inputValue && !isLoading
                                        ? handleSendClick
                                        : handleAudioClick
                                }
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <CircularProgress size={24} />
                                ) : inputValue ? (
                                    <SendIcon />
                                ) : isRecording ? (
                                    <StopIcon />
                                ) : (
                                    <AudioIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                }}
            />
        </Box>
    );
};

export default AiInstructionInputField;
