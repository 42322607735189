import { createTheme } from '@mui/material';
import '@fontsource/roboto'; // Import the Roboto font

// Default Styles for Components
const defaultComponents = {
  MuiInput: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused ': {
            borderRadius: '3px',
          },
        },
      },
      underline: {
        '&:hover:not($disabled):after': {
          borderBottom: '#babdbf',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      underlineNone: {
        '&:hover': {
          opacity: '0.5',
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: '1rem',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          opacity: 1,
        },
        '&.Mui-focusVisible': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
  }
};

const darkComponents = {
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          backgroundColor: '#282A3A',
        },
      },
    },
  },
  // Include MuiTab styles in dark mode if needed
  MuiTab: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
        '&.Mui-selected': {
          fontWeight: 600,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: '#FFFFFF',
      },
    },
  },
};

const lightComponents = {
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
  },
  // Include MuiTab styles in light mode if needed
};

const defaultTypography = (palette) => ({
  fontFamily: 'Roboto, Arial, sans-serif',
  h1: {
    fontSize: '3rem',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: '0.015em',
  },
  h2: {
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: 1.3,
    letterSpacing: '0.010em',
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: 1.4,
  },
  h4: {
    fontSize: '1.75rem',
    fontWeight: 500,
    lineHeight: 1.4,
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 300,
    color: palette.text.secondary,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: palette.text.secondary,
  },
});

const defaultPalette = {
  primary: {
    main: '#2B5766',
    light: '#d4dde0',
    dark: '#1e3c47',
    text: '#FFFFFF',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#FFD555',
    main: '#FFC107',
    dark: '#FFA000',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#5C7A8D',
    light: '#B0C4D0',
    dark: '#2D4754',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#F5F5F5',
    paper: '#FFFFFF',
  },
  border: {
    main: '#DEDEDE',
  },
  gray: {
    main: '#B8B8B8',
    dark: '#747474',
    text: '#FFFFFF',
  },
  accent: {
    main: '#747474',
  },
  disabled: {
    main: '#d2d6d9',
    text: '#454545',
  },
  separator: {
    main: '#E6E6E6',
  },
};

const lightTheme = {
  background: {
    default: '#F5F5F5',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#454545',
    secondary: '#707070',
  },
  accent: {
    main: '#747474',
  },
  separator: {
    main: '#E6E6E6',
  },
};

const darkTheme = {
  background: {
    default: '#000000',
    paper: '#282A3A',
  },
  text: {
    primary: '#F5F5F5',
    secondary: '#F5F5F5',
  },
  accent: {
    main: '#F5F5F5',
  },
  separator: {
    main: '#454545',
  },
};

// Function to Get Theme Based on Mode
const getTheme = (mode, isDarkMode) => {
  const palette = {
    mode,
    ...defaultPalette,
    ...(mode === 'dark' ? darkTheme : lightTheme),
  };

  const components = {
    ...defaultComponents,
    ...(mode === 'dark' ? darkComponents : lightComponents),
  };

  return createTheme({
    components,
    typography: defaultTypography(palette),
    palette,
  });
};

export default getTheme;
