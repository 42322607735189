import moment from "moment";
import { isInFuture, isToday } from "../../../../helper-functions";
import i18n from "../../../../i18n";


export const whenOptions = {
  'MORN': i18n.t('Morning'),
  'MORN.early': i18n.t('Early Morning'),
  'MORN.late': i18n.t('Late Morning'),
  'NOON': i18n.t('Noon'),
  'AFT': i18n.t('Afternoon'),
  'AFT.early': i18n.t('Early Afternoon'),
  'AFT.late': i18n.t('Late Afternoon'),
  'EVE': i18n.t('Evening'),
  'EVE.early': i18n.t('Early Evening'),
  'EVE.late': i18n.t('Late Evening'),
  'NIGHT': i18n.t('Night'),
  'PHS': i18n.t('After Sleep'),
  'IMD': i18n.t('Immediate'),
  'HS': i18n.t('Before Sleep'),
  'WAKE': i18n.t('After Waking Up'),
  'C': i18n.t('With Meal'),
  'CM': i18n.t('Breakfast'),
  'CD': i18n.t('Lunch'),
  'CV': i18n.t('Dinner'),
  'AC': i18n.t('Before Meal'),
  'ACM': i18n.t('Before Breakfast'),
  'ACD': i18n.t('Before Lunch'),
  'ACV': i18n.t('Before Dinner'),
  'PC': i18n.t('After Meal'),
  'PCM': i18n.t('After Breakfast'),
  'PCD': i18n.t('After Lunch'),
  'PCV': i18n.t('After Dinner')
};

export const unitsOfTime = {
  's': i18n.t('second'),
  'min': i18n.t('minute'),
  'h': i18n.t('hour'),
  'd': i18n.t('day'),
  'wk': i18n.t('week'),
  'mo': i18n.t('month'),
  'a': i18n.t('year')
}

export const dayOfWeekList = {
  'mon': i18n.t('Monday'),
  'tue': i18n.t('Tuesday'),
  'wed': i18n.t('Wednesday'),
  'thu': i18n.t('Thursday'),
  'fri': i18n.t('Friday'),
  'sat': i18n.t('Saturday'),
  'sun': i18n.t('Sunday')
};

export const parseIngredients = (ingredients) => {
  const parsedIngredients = {};

  ingredients.forEach((ingredient) => {
    parsedIngredients[ingredient.active_ingredient_code] = {
      name: ingredient.ingredient,
      dosageLabel: `${ingredient.strength} ${ingredient.strength_unit}`,
      dosageNumber: ingredient.strength,
      dosageUnit: ingredient.strength_unit,
    };

    if (ingredient.dosage_value && Number(ingredient.dosage_value)) {
      parsedIngredients[ingredient.active_ingredient_code].dosageLabel +=
        `/ ${ingredient.dosage_value} ${ingredient.dosage_unit}`;
      parsedIngredients[ingredient.active_ingredient_code].dosageNumber = ingredient.dosage_value;
      parsedIngredients[ingredient.active_ingredient_code].dosageUnit = ingredient.dosage_unit;
    }
  });

  return parsedIngredients;
};

export const parseTotalDosage = (ingredients) => {
  const totalDosage = {
    value: 0,
    unit: ingredients[0].dosageUnit,
  };

  ingredients.forEach((ingredient) => {
    totalDosage.value += Number(ingredient.dosageNumber);
  });

  return totalDosage;
};

export const handlePreset = (preset) => {
  const baseObject = {
    frequency: 1,
    period: 1,
    periodUnit: 'd',
    dayOfWeek: []
  };

  switch (preset) {
    case 'BID':
      return { ...baseObject, code: 'BID', frequency: 2 };
    case 'TID':
      return { ...baseObject, code: 'TID', frequency: 3 };
    case 'QID':
      return { ...baseObject, code: 'QID', frequency: 4 };
    case 'AM':
      return { ...baseObject, code: 'AM', when: 'MORN' };
    case 'PM':
      return { ...baseObject, code: 'PM', when: 'AFT' };
    case 'QD':
      return { ...baseObject, code: 'QD' };
    case 'QOD':
      return { ...baseObject, code: 'QOD', period: 2 };
    case 'Q1H':
      return { ...baseObject, code: 'Q1H', periodUnit: 'h' };
    case 'Q2H':
      return { ...baseObject, code: 'Q2H', period: 2, periodUnit: 'h' };
    case 'Q3H':
      return { ...baseObject, code: 'Q3H', period: 3, periodUnit: 'h' };
    case 'Q4H':
      return { ...baseObject, code: 'Q4H', period: 4, periodUnit: 'h' };
    case 'Q6H':
      return { ...baseObject, code: 'Q6H', period: 6, periodUnit: 'h' };
    case 'Q8H':
      return { ...baseObject, code: 'Q8H', period: 8, periodUnit: 'h' };
    case 'BED':
      return { ...baseObject, code: 'BED', when: 'HS' };
    case 'WK':
      return { ...baseObject, code: 'WK', periodUnit: 'wk' };
    case 'MO':
      return { ...baseObject, code: 'MO', periodUnit: 'mo' };
    default:
      return null;
  }
};

export const formikToApi = (values) => {
  const body = {};
  const { timing, doseValue, doseUnit, maxDosePeriod, additionalInstruction, ...rest } = values;

  body['doseAndRate'] = [{
    doseQuantity: {
      value: doseValue,
      unit: doseUnit,
    },
  }];

  const timingObject = {}
  const repeat = {};

  if (maxDosePeriod.maxDose && maxDosePeriod.maxDoseUnit && maxDosePeriod.period && maxDosePeriod.periodUnit) {
    body.maxDosePerPeriod = [{
      numerator: {
        value: maxDosePeriod.maxDose,
        unit: maxDosePeriod.maxDoseUnit,
      },
      denominator: {
        value: maxDosePeriod.period,
        unit: maxDosePeriod.periodUnit,
      },
    }];
  }

  if (timing.id) {
    timingObject.id = timing.id;
  }

  for (const key in timing) {
    if (key === 'code' && timing[key]) {
      timingObject['code'] = { coding: [{ code: timing[key] }] };
      continue;
    }

    if (timing[key]) {
      // Check if timing[key] is an array and ensure it's not empty
      if (Array.isArray(timing[key])) {
        if (timing[key].length > 0) {
          repeat[key] = timing[key];
        }
      } else {
        repeat[key] = timing[key];
      }
    }
  }

  timingObject['repeat'] = repeat;
  body['timing'] = timingObject;

  for (const key in rest) {
    if (key === 'site' || key === 'route' || key === 'method') {
      if (rest[key] !== null) {
        body[key] = { text: rest[key] }
      }
    } else if (rest[key]) {
      body[key] = rest[key];
    }
  }

  // Handle additionalInstruction
  if (additionalInstruction && additionalInstruction.length > 0) {
    body['additionalInstruction'] = additionalInstruction.map(instr => ({ text: instr }));
  }

  return body;
}



export const getStatus = (startDate, endDate) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : null;

  if (start > now) {
    return 'proposed';
  } else if (end && end < now) {
    return 'completed';
  } else {
    return 'active';
  }
}

export function formatDosageToMarkdown(dosage, t) {
  let markdown = "";

  // if (dosage.sequence) {
  //   markdown += `**${t('Sequence')}:** ${dosage.sequence}\n\n`;
  // }

  if (dosage.text) {
    markdown += `**${t('Dosage Instruction')}:** ${dosage.text}\n\n`;
  }

  if (dosage.additionalInstruction && dosage.additionalInstruction.length > 0) {
    const additionalInstructions = dosage.additionalInstruction
      .map((instruction) => instruction.text)
      .join(", ");
    markdown += `**${t('Additional Instruction')}:** ${additionalInstructions}\n\n`;
  }

  if (dosage.patientInstruction) {
    markdown += `**${t('Patient Instruction')}:** ${dosage.patientInstruction}\n\n`;
  }

  // Complete handling of timing field
  if (dosage.timing) {
    // Handling event (specific date/time of administration)
    if (dosage.timing.event && Array.isArray(dosage.timing.event) && dosage.timing.event.length > 0) {
      const events = dosage.timing.event.map(event => new Date(event).toLocaleString()).join(', ');
      markdown += `**${t('Event')}:** ${events}\n\n`;
    }

    if (dosage.timing.repeat) {
      const {
        frequency, frequencyMax, period, periodMax, periodUnit,
        duration, durationMax, durationUnit,
        timeOfDay, dayOfWeek, when, offset, count, countMax
      } = dosage.timing.repeat;

      // Frequency and maximum frequency
      if (frequency && period && periodUnit) {
        markdown += `**${t('Frequency')}:** ${frequency}`;
        if (frequencyMax) {
          markdown += ` - ${frequencyMax}`;
        }
        markdown += ` ${t('times')} ${t('every')} ${period}`;
        if (periodMax) {
          markdown += ` - ${periodMax}`;
        }
        markdown += ` ${t(`time.${periodUnit}`, { count: period })}`;
        // Timing Code
        if (dosage.timing.code && dosage.timing.code.coding && dosage.timing.code.coding.length > 0) {
          markdown += ` ( ${dosage.timing.code.coding[0].display || dosage.timing.code.coding[0].code} )`;
        }
        markdown += '\n\n';
      }

      // Duration
      if (duration && durationUnit) {
        markdown += `**${t('Duration')}:** ${duration}`;
        if (durationMax) {
          markdown += ` - ${durationMax}`;
        }
        markdown += ` ${t(`time.${durationUnit}`, { count: durationMax || duration })}\n\n`;
      }

      // Time of day
      if (timeOfDay && Array.isArray(timeOfDay) && timeOfDay.length > 0) {
        const times = timeOfDay.join(", ");
        markdown += `**${t('Time of Day')}:** ${times}\n\n`;
      }

      // Days of the week
      if (dayOfWeek && Array.isArray(dayOfWeek) && dayOfWeek.length > 0) {
        const days = dayOfWeek.map(it => dayOfWeekList[it]).join(", ");
        markdown += `**${t('Day of Week')}:** ${days}\n\n`;
      }

      // When
      if (when && Array.isArray(when) && when.length > 0) {
        const joinedWhen = Array.isArray(when) ? when.map(it => whenOptions[it]).join(", ") : whenOptions[when];
        markdown += `**${t('When')}:** ${joinedWhen}\n\n`;
      }

      // Offset
      if (offset) {
        markdown += `**${t('Offset')}:** ${offset} ${t('minutes')}\n\n`;
      }

      // Count (total number of doses)
      if (count) {
        markdown += `**${t('Count')}:** ${count}`;
        if (countMax) {
          markdown += ` - ${countMax}`;
        }
        markdown += ` ${t('total doses')}\n\n`;
      }

      // Bounds (duration, range, or period)
      if (dosage.timing.repeat.boundsDuration) {
        const { value, unit } = dosage.timing.repeat.boundsDuration;
        markdown += `**${t('Bounds Duration')}:** ${value} ${unit}\n\n`;
      }
      if (dosage.timing.repeat.boundsRange) {
        const { low, high } = dosage.timing.repeat.boundsRange;
        markdown += `**${t('Bounds Range')}:** ${low.value} ${low.unit} - ${high.value} ${high.unit}\n\n`;
      }
      if (dosage.timing.repeat.boundsPeriod) {
        const { start, end } = dosage.timing.repeat.boundsPeriod;
        markdown += `**${t('Bounds Period')}:** ${new Date(start).toLocaleDateString()} - ${new Date(end).toLocaleDateString()}\n\n`;
      }
    }
  }

  // Handling other dosage fields
  if (dosage.asNeeded === true) {
    markdown += `**${t('As Needed')}:** ${t('Yes')}\n\n`;
  }

  if (dosage.asNeededFor) {
    const asNeededCode = dosage.asNeededFor.coding[0].display || dosage.asNeededFor.coding[0].code;
    markdown += `**${t('As Needed For')}:** ${asNeededCode}\n\n`;
  }

  if (dosage.site) {
    if (dosage.site.concept && dosage.site.text) {
      markdown += `**${t('Site')}:** ${dosage.site.text}\n\n`;
    } else if (dosage.site.reference) {
      // Handle reference to BodyStructure
      markdown += `**${t('Site')}:** ${t('See reference to BodyStructure')}\n\n`;
    }
  }

  if (dosage.route && dosage.route.text) {
    markdown += `**${t('Route')}:** ${dosage.route.text}\n\n`;
  }

  if (dosage.method && dosage.method.text) {
    markdown += `**${t('Method')}:** ${dosage.method.text}\n\n`;
  }

  if (dosage.doseAndRate && dosage.doseAndRate.length > 0) {
    dosage.doseAndRate.forEach((doseRate) => {
      if (doseRate.type && doseRate.type.coding && doseRate.type.coding.length > 0) {
        markdown += `**${t('Dose Type')}:** ${doseRate.type.coding[0].display || doseRate.type.coding[0].code}\n\n`;
      }

      if (doseRate.doseQuantity && doseRate.doseQuantity.value && doseRate.doseQuantity.unit) {
        markdown += `**${t('Dose Quantity')}:** ${doseRate.doseQuantity.value} ${doseRate.doseQuantity.unit}\n\n`;
      }

      if (doseRate.doseRange) {
        const { low, high } = doseRate.doseRange;
        if (low && high) {
          markdown += `**${t('Dose Range')}:** ${low.value} ${low.unit} - ${high.value} ${high.unit}\n\n`;
        } else if (low) {
          markdown += `**${t('Dose Range')}:** ${low.value} ${low.unit} ${t('and above')}\n\n`;
        } else if (high) {
          markdown += `**${t('Dose Range')}:** ${t('Up to')} ${high.value} ${high.unit}\n\n`;
        }
      }

      if (doseRate.rateQuantity && doseRate.rateQuantity.value && doseRate.rateQuantity.unit) {
        markdown += `**${t('Rate Quantity')}:** ${doseRate.rateQuantity.value} ${doseRate.rateQuantity.unit}\n\n`;
      }

      if (doseRate.rateRange) {
        const { low, high } = doseRate.rateRange;
        if (low && high) {
          markdown += `**${t('Rate Range')}:** ${low.value} ${low.unit} - ${high.value} ${high.unit}\n\n`;
        } else if (low) {
          markdown += `**${t('Rate Range')}:** ${low.value} ${low.unit} ${t('and above')}\n\n`;
        } else if (high) {
          markdown += `**${t('Rate Range')}:** ${t('Up to')} ${high.value} ${high.unit}\n\n`;
        }
      }

      if (doseRate.rateRatio) {
        markdown += `**${t('Rate Ratio')}:** ${doseRate.rateRatio.numerator.value} ${doseRate.rateRatio.numerator.unit} ${t('per')} ${doseRate.rateRatio.denominator.value} ${t(`time.${doseRate.rateRatio.denominator.unit}`, { count: doseRate.rateRatio.denominator.value })}\n\n`;
      }

      if (doseRate.rateString) {
        markdown += `**${t('Rate')}:** ${doseRate.rateString}\n\n`;
      }
    });
  }

  if (dosage.maxDosePerAdministration) {
    markdown += `**${t('Maximum Dose Per Administration')}:** ${dosage.maxDosePerAdministration.value} ${dosage.maxDosePerAdministration.unit}\n\n`;
  }

  if (dosage.maxDosePerPeriod && Array.isArray(dosage.maxDosePerPeriod)) {
    dosage.maxDosePerPeriod.forEach((dosePeriod) => {
      const { numerator, denominator } = dosePeriod;
      if (numerator && denominator) {
        markdown += `**${t('Maximum Dose Per Period')}:** ${numerator.value} ${numerator.unit} ${t('per')} ${denominator.value} ${t(`time.${denominator.unit}`, { count: denominator.value })}\n\n`;
      }
    });
  }

  if (dosage.maxDosePerLifetime) {
    markdown += `**${t('Maximum Dose Per Lifetime')}:** ${dosage.maxDosePerLifetime.value} ${dosage.maxDosePerLifetime.unit}\n\n`;
  }

  if (dosage.maxTreatmentPeriod) {
    const { value, unit } = dosage.maxTreatmentPeriod;
    markdown += `**${t('Maximum Treatment Period')}:** ${value} ${unit}\n\n`;
  }

  return markdown || t('No dosage information available');
}


export const processDosage = (dosage) => {
  const timing = {
    id: dosage.timing?.id || null,
    code: dosage.timing?.code?.coding?.[0]?.code || null,
    frequency: dosage.timing?.repeat?.frequency || 1,
    frequencyMax: dosage.timing?.repeat?.frequencyMax || null,
    period: dosage.timing?.repeat?.period || 1,
    periodUnit: dosage.timing?.repeat?.periodUnit || 'd',
    when: dosage.timing?.repeat?.when || [],
    offset: dosage.timing?.repeat?.offset || null,
    duration: dosage.timing?.repeat?.duration || null,
    durationUnit: dosage.timing?.repeat?.durationUnit || null,
    dayOfWeek: dosage.timing?.repeat?.dayOfWeek || [],
    timeOfDay: dosage.timing?.repeat?.timeOfDay || null,
    count: dosage.timing?.repeat?.count || null,
    countMax: dosage.timing?.repeat?.countMax || null,
  };

  const maxDosePeriod = {
    period: dosage.maxDosePerPeriod?.[0]?.denominator?.value || null,
    periodUnit: dosage.maxDosePerPeriod?.[0]?.denominator?.unit || '',
    maxDose: dosage.maxDosePerPeriod?.[0]?.numerator?.value || null,
    maxDoseUnit: dosage.maxDosePerPeriod?.[0]?.numerator?.unit || null,
  };

  // Extract additionalInstruction
  const additionalInstruction = dosage.additionalInstruction
    ? dosage.additionalInstruction.map(instr => instr.text)
    : [];

  return {
    ...dosage,
    asNeeded: dosage.asNeeded || false,
    doseValue: dosage.doseAndRate?.[0]?.doseQuantity?.value,
    doseUnit: dosage.doseAndRate?.[0]?.doseQuantity?.unit,
    site: dosage.site?.text || null,
    route: dosage.route?.text || null,
    method: dosage.method?.text || null,
    timing,
    maxDosePeriod,
    additionalInstruction,
  };
};

// Updated APIToFormik function
export const APIToFormik = (medication) => {
  if (!medication.dosage_fhir || medication.dosage_fhir.length === 0) {
    return null;
  }

  const dosage = processDosage(medication.dosage_fhir[0]);

  const formikValues = {
    ...dosage,
    startDate: medication.start_date,
    endDate: medication.end_date || null,
    isMaxTolerated: medication.max_tolerated_dosage,
  };

  return formikValues;
};



export function generateMedicationInfo(medInfo, initDosage) {
  // Create brand name heading
  let markdown = `## ${medInfo.brand_name}\ `;
  markdown += `\n**Dose**: ${initDosage.value} ${initDosage.unit}\n`;

  return markdown;
}


// Function to parse only the 'item' object
export const parseMedicationItem = (item) => {
  const aiCodeArr = item.medication.active_ingredient.map((el) => el.active_ingredient_code);

  const period = item.titration
    ? moment(item.titration.next_dose_date).diff(moment(Date.now()), 'days')
    : null;

  const rx = {
    uuid: item.uuid,
    name: item.medication.brand_name,
    medUuid: item.medication.uuid,
    has_request_orchestration: item.has_request_orchestration,
    daily: '',
    target: item.titration ? item.titration.target_dose : null,
    nextDose: item.titration ? item.titration.next_dose : null,
    nextDoseDate: item.titration ? item.titration.next_dose_date : null,
    nextDoseTime: period !== null ? parseInt(period, 10) : null,
    statusName: null, // To be enriched later
    statusColor: null, // To be enriched later
    posology: '',
    startDate: item.start_date,
    endDate: item.end_date ? item.end_date : '---',
    lastActiveDay: isToday(item.end_date),
    isIntended: isInFuture(item.start_date),
    max_tolerated_dosage: item.max_tolerated_dosage,
    dosage: item.dosage_fhir[0],
    unit: '',
    unitFr: '',
    isNotSuggested: null, // To be enriched later
    aiCode: aiCodeArr,
    cautions: null, // To be enriched later
    ingredient: [],
  };

  item.medication.active_ingredient.forEach((med) => {
    parseMedIngredients(med, rx);
  });

  return rx;
};

const parseMedIngredients = (arrayItem, element) => {
  const obj = {};

  obj.strength = `${parseFloat(arrayItem.strength)} ${arrayItem.strength_unit.toLowerCase()}`;
  obj.strengthFr = `${parseFloat(arrayItem.strength)} ${arrayItem.strength_unit_fr.toLowerCase()}`;
  obj.unit = arrayItem.strength_unit.toLowerCase();
  obj.dosageUnit = arrayItem.dosage_unit.toLowerCase();
  obj.dosageUnitFr = arrayItem.dosage_unit_fr.toLowerCase();
  obj.drugUuid = arrayItem.uuid;
  obj.molecule = arrayItem.ingredient.toLowerCase();
  obj.moleculeFr =
    arrayItem.ingredient_fr === 'nan' ? arrayItem.ingredient.toLowerCase() : arrayItem.ingredient_fr.toLowerCase();
  // Pushing stuff up one level.
  // If null posology, we return N.A.; else we concatenate string when unit and dosage unit is not 'nan'
  element.unit = element.daily !== null
    ? `${arrayItem.strength_unit} ${arrayItem.dosage_unit === 'nan' ? '' : `/ ${arrayItem.dosage_unit}`}`.toLowerCase()
    : '---';

  element.unitFr = element.daily !== null
    ? `${arrayItem.strength_unit_fr} ${arrayItem.dosage_unit_fr === 'nan' ? '' : `/ ${arrayItem.dosage_unit_fr}`
      }`.toLowerCase()
    : '---';
  element.ingredient.push(obj);
};

// Function to enrich parsed 'rx' with 'titrationStat' and 'suggestedMedications'
export const enrichMedicationData = (item, titrationStat = [], suggestedMedications = []) => {
  const rx = parseMedicationItem(item);
  const aiCodeArr = rx.aiCode;
  const suggestedCodes = suggestedMedications.map((el) => el.list).flat();

  const asTitrationCaution =
    suggestedMedications.length > 0 &&
    aiCodeArr.length > 0 &&
    suggestedMedications.filter((el) => aiCodeArr.some((r) => el.list.includes(r)));

  const isSuggested =
    suggestedCodes.length > 0 &&
    aiCodeArr.length > 0 &&
    suggestedCodes.filter((el) => aiCodeArr.includes(el));

  rx.statusName = item.titration && titrationStat[item.titration.status]?.name;
  rx.statusColor = item.titration && titrationStat[item.titration.status]?.color_code.bright;
  rx.isNotSuggested = isSuggested ? !(isSuggested.length > 0) : true;
  rx.cautions = asTitrationCaution && asTitrationCaution[0];

  return rx;
};

