import React from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ErrorFallback = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
    window.location.reload();
  };

  return (
    <Box
      role="alert"
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: '#dff9f3', // Updated background color
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        component="img"
        src="/img/robot-error.png"
        alt="Error illustration"
        sx={{
          width: { xs: '80%', sm: '300px' }, // Responsive sizing
          height: 'auto',
          mb: 4,
          animation: 'float 4s ease-in-out infinite', // Added animation
        }}
      />

      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          {t('Oops! Something went wrong')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {t(
            'We’re experiencing an unexpected issue. Our team is working to resolve it as soon as possible.'
          )}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoBack}
          sx={{ mr: 2 }}
        >
          {t('Return to Dashboard')}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          href="mailto:support@greybox.ca"
        >
          {t('Contact Support')}
        </Button>
      </Container>

    </Box>
  );
};

export default ErrorFallback;
