import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const FormikSelect = ({
  name,
  label,
  required = false,
  sx = {},
  children,
  disabled = false,
  multiple = false,
  fullWidth = false, // Add fullWidth prop with default value
  ...other
}) => {
  const [field, meta] = useField(name);
  const error = Boolean(meta.touched && meta.error);
  const helperText = meta.touched && meta.error;
  const ariaDescribedBy = error ? `${name}-helper-text` : undefined;

  return (
    <FormControl
      error={error}
      disabled={disabled}
      fullWidth={fullWidth} // Add fullWidth to FormControl
      sx={{
        ...sx, // Merge any custom styles passed as props
      }}
    >
      <InputLabel id={`${name}-label`}>{required ? `${label} *` : label}</InputLabel>
      <Select
        id={name}
        labelId={`${name}-label`}
        name={name}
        label={required ? `${label} *` : label} // Add asterisk if required
        multiple={multiple}
        data-cy={name}
        aria-describedby={ariaDescribedBy}
        fullWidth={fullWidth} // Add fullWidth to Select
        {...field}
        {...other}
      >
        {children}
      </Select>
      {error && (
        <FormHelperText id={ariaDescribedBy}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikSelect;
